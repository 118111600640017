
import { defineComponent } from 'vue'
import Pagination from '@/components/molecules/Pagination.vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import BuildingTable from '@/components/molecules/table/BuildingTable.vue'
import UnitTable from '@/components/molecules/table/UnitTable.vue'
import LinkPopup from '@/components/molecules/LinkPopup.vue'
import Info from '@/assets/svg/info.svg?inline'
import NoProperty from '@/assets/svg/no-property.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

import SearchContainer from '@/components/molecules/SearchContainer.vue'
// import NoSearchIcon from '@/assets/svg/no-search.svg?inline'
// import Copy from '@/assets/svg/copy-link.svg?inline'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'PropertySection',
  data () {
    return {
      loading: false,
      message: '',
      preview: null,
      propertyList: {},
      propertyMeta: null,
      propertyMetaUnit: null,
      page: 1,
      pageUnit: 1,
      isModalOpen: false,
      isModalUnitOpen: false,
      deleteBuildingId: null,
      errorDeleteBuilding: null,
      errorDeleteUnitMessage: null,
      searchData: '',
      coming_soon: false,
      filter: 'unit_overview',
      isLoading: true,
      deleteUnitId: null,
      isModalVisitLinkOpen: false,
      isModalHandoverLinkOpen: false,
      selectedLanguageTempalte: false,
      selectedLanguage: '',
      linkData: '',
      linkCopied: false,
      linkText: '',
      errorUnit: null,
      unitId: null,
      updateSlotsTempalte: false,
      linkTempalte: false,
      unitLinkId: '',
      isModalPublishUnitOpen: false,
      isModalUnpublishUnitOpen: false,
      isModalDraftUnitOpen: false,
      visitLink: null,
      errorUpublishPopup: false,
      errorUpublish: null,
      inActiveHandover: false
    }
  },
  mounted () {
    this.getProperties()
    window.addEventListener('click', this.hideErrors)
  },
  components: {
    Pagination,
    DefaultPopup,
    BuildingTable,
    UnitTable,
    // NoSearch,
    AlertBlock,
    Info,
    SearchContainer,
    // NoSearchIcon,
    // Copy,
    LottieAnimation: Vue3Lottie,
    LinkPopup,
    NoProperty
  },
  methods: {
    hideErrors () {
      this.errorUnit = null
    },
    getProperties (searchData) {
      let url = ''
      if (this.filter === 'unit_overview') {
        url += `?page=${this.pageUnit}`
        if (searchData !== undefined) {
          url += `&search=${searchData}`
        }
        url += '&filter[unit_overview]=true'
        this.$store.dispatch('property/getUnits', url).then(
          (res) => {
            this.propertyList = res.data
            this.propertyMetaUnit = res.meta
            this.isLoading = false
          },
          (error) => {
            this.error = error.response.data.message
            this.isLoading = false
          }
        )
      }
      if (this.filter === 'buildings_overview') {
        url += `?page=${this.page}`
        if (searchData !== undefined) {
          url += `&search=${searchData}`
        }
        url += '&filter[buildings_overview]=true'
        this.$store.dispatch('property/getBuildings', url).then(
          (res) => {
            this.propertyList = res.data
            this.propertyMeta = res.meta
            this.isLoading = false
          },
          (error) => {
            this.error = error.response.data.message
            this.isLoading = false
          }
        )
      }
    },
    handleGoToBuilding (id) {
      this.$router.push(`/admin/building/${id}`)
    },
    handleCreateBuilding () {
      this.$router.push('/dashboard/create-building')
    },
    getLink (unitId, type) {
      const data = {
        unitId: unitId,
        type: type
      }
      this.$store.dispatch('property/getLink', data).then(
        (res) => {
          console.log(res)
          this.linkData = res.data
          this.visitLink = this.linkData
          if (this.selectedLanguage === 'german') {
            this.linkText = `Um die Besichtigung durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy: ${this.linkData}`
          } else if (this.selectedLanguage === 'english') {
            this.linkText = `To conduct the visit, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${this.linkData}`
          } else this.linkText = `To conduct the visit, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${this.linkData}\nUm die Besichtigung durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy: ${this.linkData}`
        },
        (error) => {
          this.errorDeleteBuilding = error.response.data.errors
        }
      )
    },
    deleteBuilding (buildingId) {
      buildingId = this.deleteBuildingId
      this.$store.dispatch('property/deleteBuilding', buildingId).then(
        (res) => {
          this.getProperties()
          this.isModalOpen = false
          buildingId = null
        },
        (error) => {
          this.errorDeleteBuilding = error.response.data.errors
          this.errorDeleteUnitMessage = error.response.data.message
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getProperties()
    },
    handlePageUnit (pageUnit) {
      this.pageUnit = pageUnit
      this.getProperties()
    },
    handleOpenModal (id) {
      this.deleteBuildingId = id
      this.isModalOpen = true
    },
    handleOpenUnitModal (id) {
      this.deleteUnitId = id
      this.isModalUnitOpen = true
    },
    handleCloseModal () {
      this.isModalOpen = false
      this.errorDeleteUnitMessage = null
    },
    handleCloseDraftUnitModal () {
      this.isModalDraftUnitOpen = false
    },
    handleCloseUnitModal () {
      this.isModalUnitOpen = false
      this.errorDeleteUnitMessage = null
    },
    handleFilter (filter) {
      this.filter = filter
      this.isLoading = true
      this.getProperties()
    },
    publishUnitPopup (unitId, inActiveHandover) {
      this.inActiveHandover = inActiveHandover
      this.unitId = unitId
      this.isModalPublishUnitOpen = true
    },
    handleClosePublishUnitModal () {
      this.isModalPublishUnitOpen = false
    },
    publishUnit (data) {
      this.errorUnit = null
      data.unitId = this.unitId
      data.handover_cancel = true
      this.$store.dispatch('property/publishUnit', data).then(
        () => {
          this.getProperties()
          this.isModalPublishUnitOpen = false
        },
        (error) => {
          this.errorUnit = error.response.data
          this.isModalPublishUnitOpen = false
        }
      )
    },
    unpublishUnitPopup (unitId) {
      this.unitId = unitId
      this.isModalUnpublishUnitOpen = true
    },
    handleCloseUnpublishUnitModal () {
      this.isModalUnpublishUnitOpen = false
    },
    unpublishUnit () {
      this.errorUnit = null
      this.$store.dispatch('property/unpublishedUnit', this.unitId).then(
        () => {
          this.getProperties()
          this.isModalUnpublishUnitOpen = false
        },
        (error) => {
          this.errorUpublish = error.response.data.message
          if (this.errorUpublish === 'Unit has active visit') {
            this.errorUpublishPopup = true
            this.isModalUnpublishUnitOpen = false
          }
        }
      )
    },
    handleCloseUnpublishVisitUnitModal () {
      this.errorUpublishPopup = false
      this.isModalUnpublishUnitOpen = false
    },
    deleteUnit () {
      this.$store.dispatch('property/deleteUnit', this.deleteUnitId).then(
        () => {
          this.getProperties()
          this.isModalUnitOpen = false
          this.deleteUnitId = null
        },
        (error) => {
          this.errorDeleteUnitMessage = error.response.data.message
        }
      )
    },
    openModalVisitLink (unitId) {
      this.isModalVisitLinkOpen = true
      this.getLink(unitId, 'visit')
      this.unitLinkId = unitId
    },
    closeModalVisitLink () {
      this.isModalVisitLinkOpen = false
      this.selectedLanguage = ''
      this.selectedLanguageTempalte = false
      this.linkTempalte = false
      this.updateSlotsTempalte = false
      this.linkCopied = false
    },
    openModelHandoverLink (unitId) {
      this.isModalHandoverLinkOpen = true
      this.getLink(unitId, 'handover')
      this.unitLinkId = unitId
    },
    closeModelHandoverLink () {
      this.isModalHandoverLinkOpen = false
      this.selectedLanguage = ''
      this.selectedLanguageTempalte = false
      this.linkTempalte = false
      this.updateSlotsTempalte = false
      this.linkCopied = false
    },
    copyLink () {
      const link = this.linkData
      const textarea = document.createElement('textarea')
      if (this.selectedLanguage === 'german') {
        textarea.value = `Um die Besichtigung durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy ${link}`
      } else if (this.selectedLanguage === 'english') {
        textarea.value = `To conduct the visit, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${link}`
      } else textarea.value = `Um die Besichtigung durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy ${link}\nTo conduct the visit, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${link}`
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.linkCopied = true
    },
    copyLinkHandover () {
      const link = this.linkData
      const textarea = document.createElement('textarea')
      if (this.selectedLanguage === 'german') {
        textarea.value = `Um die Übergabe durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy: ${link}`
      } else if (this.selectedLanguage === 'english') {
        textarea.value = `To conduct the handover, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${link}`
      } else textarea.value = `To conduct the handover, download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone: ${link}\nUm die Übergabe durchzuführen, laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy: ${link}`
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.linkCopied = true
    },
    choosenLang (lang) {
      this.updateSlotsTempalte = true
      this.selectedLanguageTempalte = true
      this.linkTempalte = false
      this.selectedLanguage = lang
    },
    goToCopyLinkTemplate () {
      this.updateSlotsTempalte = false
      this.selectedLanguageTempalte = false
      this.linkTempalte = true
    },
    backToUpdateTemplate () {
      this.updateSlotsTempalte = true
      this.selectedLanguageTempalte = true
      this.linkTempalte = false
    },
    backToLangTemplate () {
      this.updateSlotsTempalte = false
      this.selectedLanguageTempalte = false
      this.linkTempalte = false
    },
    updateSlots () {
      this.$router.push('/dashboard/create-slots?unitId=' + this.unitLinkId)
    },
    copyUnit (id) {
      this.$store.dispatch('property/copyUnit', id).then(
        (res) => {
          console.log(res.data.id)
          this.$router.push(`/dashboard/create-unit-step-one?unitId=${res.data.id}`)
        },
        (error) => {
          this.isModalDraftUnitOpen = true
          this.error = error.response.data.message
        }
      )
    }
  }
})
