
import { defineComponent } from 'vue'
import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import BuildingInfo from '@/assets/svg/building-info.svg?inline'
import Info from '@/assets/svg/info.svg?inline'
import NewBuilding from '@/assets/svg/new-building.svg?inline'
import More from '@/assets/svg/more.svg?inline'

export default defineComponent({
  name: 'BuildingTable',
  data () {
    return {}
  },
  props: {
    buildingData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deleteBuilding: {
      type: Function,
      default: () => {
        return false
      }
    },
    handleChooseBuilding: {
      type: [Function, Boolean],
      default: false
    },
    handleGoToBuilding: {
      type: [Function, Boolean],
      default: false
    }
  },
  components: {
    Edit,
    Delete,
    BuildingInfo,
    Info,
    NewBuilding,
    More
  },
  methods: {
    editBuilding (id) {
      this.$router.push(`/dashboard/create-building?buildingId=${id}`)
    },
    createUnit (id) {
      this.$router.push(`/dashboard/create-unit-step-one?buildingId=${id}`)
    },
    goToBuildinDetails (id) {
      this.$router.push(`/dashboard/building-details?buildingId=${id}`)
    }
  }
})
