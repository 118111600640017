import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-288bee87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "building-card" }
const _hoisted_2 = { class: "building-card__svg-hint" }
const _hoisted_3 = { class: "building-card__action-group" }
const _hoisted_4 = { class: "building-card__svg-group" }
const _hoisted_5 = { class: "building-card__svg-hint" }
const _hoisted_6 = { class: "building-card__svg-group" }
const _hoisted_7 = { class: "building-card__svg-hint" }
const _hoisted_8 = { class: "building-card__svg-group" }
const _hoisted_9 = { class: "building-card__svg-hint" }
const _hoisted_10 = { class: "building-card__svg-group" }
const _hoisted_11 = { class: "building-card__svg-hint" }
const _hoisted_12 = { class: "building-card__mobile-more" }
const _hoisted_13 = { class: "building-card__mobile-more-wrap" }
const _hoisted_14 = { class: "building-card__mobile-bottom-block" }
const _hoisted_15 = { class: "building-card__svg-group" }
const _hoisted_16 = { class: "building-card__svg-hint" }
const _hoisted_17 = { class: "building-card__svg-group" }
const _hoisted_18 = { class: "building-card__svg-hint" }
const _hoisted_19 = { class: "building-card__svg-group" }
const _hoisted_20 = { class: "building-card__svg-hint" }
const _hoisted_21 = { class: "building-card__svg-group" }
const _hoisted_22 = { class: "building-card__svg-hint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Info = _resolveComponent("Info")!
  const _component_NewBuilding = _resolveComponent("NewBuilding")!
  const _component_BuildingInfo = _resolveComponent("BuildingInfo")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_More = _resolveComponent("More")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.building_address')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.units_listed_of_units_total')), 1),
          _createElementVNode("th", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.actions')) + " ", 1),
            _createVNode(_component_Info, { class: "building-card__info" }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.actions_hint')), 1)
          ])
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildingData, (item) => {
        return (_openBlock(), _createElementBlock("tbody", {
          key: item.id
        }, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, _toDisplayString(item?.full_address), 1),
            _createElementVNode("td", null, _toDisplayString(item?.units_data?.listed) + "/" + _toDisplayString(item?.units_data?.all), 1),
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_NewBuilding, {
                  onClick: ($event: any) => (_ctx.createUnit(item?.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('message.property.add_unit')), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_BuildingInfo, {
                  onClick: ($event: any) => (_ctx.goToBuildinDetails(item?.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('message.property.more_info_about_building')), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Edit, {
                  onClick: ($event: any) => (_ctx.editBuilding(item?.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('message.property.edit_building')), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_Delete, {
                  onClick: ($event: any) => (_ctx.deleteBuilding(item?.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('message.delete')), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_12, [
              _createVNode(_component_More),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_NewBuilding, {
                      onClick: ($event: any) => (_ctx.createUnit(item?.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('message.property.add_unit')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_BuildingInfo, {
                      onClick: ($event: any) => (_ctx.goToBuildinDetails(item?.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('message.property.more_info_about_building')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_Edit, {
                      onClick: ($event: any) => (_ctx.editBuilding(item?.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('message.property.edit_building')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_Delete, {
                      onClick: ($event: any) => (_ctx.deleteBuilding(item?.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('message.delete')), 1)
                  ])
                ])
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}