import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cfb013e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "property-section" }
const _hoisted_2 = { class: "property-section__wrap property-section__tools-wrap" }
const _hoisted_3 = { class: "property-section__title" }
const _hoisted_4 = { class: "property-section__wrap" }
const _hoisted_5 = { class: "property-section__filter" }
const _hoisted_6 = { class: "property-section__svg-hint" }
const _hoisted_7 = { class: "property-section__svg-hint" }
const _hoisted_8 = {
  key: 0,
  class: "property-section__wrap-container"
}
const _hoisted_9 = { class: "property-section__wrap" }
const _hoisted_10 = {
  key: 0,
  class: "property-section__no-search"
}
const _hoisted_11 = { class: "property-section__no-visit-text" }
const _hoisted_12 = {
  key: 1,
  class: "property-section__wrap-container"
}
const _hoisted_13 = { class: "property-section__wrap" }
const _hoisted_14 = {
  key: 0,
  class: "property-section__no-search"
}
const _hoisted_15 = { class: "property-section__no-visit-text" }
const _hoisted_16 = { class: "property-section__modal" }
const _hoisted_17 = { class: "property-section__modal-text" }
const _hoisted_18 = { class: "property-section__modal-buttons" }
const _hoisted_19 = {
  key: 0,
  class: "error-delete"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "property-section__modal" }
const _hoisted_22 = { class: "property-section__modal-text" }
const _hoisted_23 = { class: "property-section__modal-buttons" }
const _hoisted_24 = {
  key: 0,
  class: "error-delete"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "property-section__modal" }
const _hoisted_27 = { class: "property-section__modal-text" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "property-section__modal-buttons" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { class: "property-section__modal" }
const _hoisted_32 = { class: "property-section__modal-text" }
const _hoisted_33 = { class: "property-section__modal-buttons" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "property-section__modal" }
const _hoisted_36 = { class: "property-section__modal-text" }
const _hoisted_37 = { class: "property-section__modal-buttons" }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "property-section__modal" }
const _hoisted_40 = { class: "property-section__modal-text" }
const _hoisted_41 = { class: "property-section__modal-buttons" }
const _hoisted_42 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_SearchContainer = _resolveComponent("SearchContainer")!
  const _component_Info = _resolveComponent("Info")!
  const _component_BuildingTable = _resolveComponent("BuildingTable")!
  const _component_NoProperty = _resolveComponent("NoProperty")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_UnitTable = _resolveComponent("UnitTable")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!
  const _component_LinkPopup = _resolveComponent("LinkPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LottieAnimation, {
          key: 0,
          animationData: require('@/assets/preloader/load.json')
        }, null, 8, ["animationData"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.property.property')), 1),
        _createVNode(_component_SearchContainer, {
          "search-data": this.searchData,
          placeholderData: _ctx.$t('message.property.search'),
          "search-function": this.getProperties,
          class: "property-section__search"
        }, null, 8, ["search-data", "placeholderData", "search-function"]),
        _createElementVNode("div", {
          class: "property-section__button-add",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCreateBuilding && _ctx.handleCreateBuilding(...args)))
        }, _toDisplayString(_ctx.$t('message.property.add_building')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFilter('unit_overview'))),
            class: _normalizeClass(['property-section__filter-item', this.filter === 'unit_overview' && 'active'])
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.property.unit_overview')) + " ", 1),
            _createVNode(_component_Info),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('message.property.unit_overview_hint')), 1)
          ], 2),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleFilter('buildings_overview'))),
            class: _normalizeClass(['property-section__filter-item', this.filter === 'buildings_overview' && 'active'])
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.property.buildings_overview')) + " ", 1),
            _createVNode(_component_Info),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('message.property.buildings_overview_hint')), 1)
          ], 2)
        ])
      ]),
      (this.filter === 'buildings_overview')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_BuildingTable, {
                "building-data": this.propertyList,
                "delete-building": this.handleOpenModal
              }, null, 8, ["building-data", "delete-building"])
            ]),
            (this.propertyList.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_NoProperty),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('message.property.no_bilding_yet')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Pagination, {
              propertyMeta: _ctx.propertyMeta,
              propertyList: _ctx.propertyList,
              page: _ctx.page,
              handlePage: _ctx.handlePage
            }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
          ]))
        : _createCommentVNode("", true),
      (this.filter === 'unit_overview')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_UnitTable, {
                "unit-data": this.propertyList,
                "delete-unit": this.handleOpenUnitModal,
                "publish-unit": this.publishUnitPopup,
                "unpublish-unit": this.unpublishUnitPopup,
                "open-visit-link": this.openModalVisitLink,
                "open-handover-link": this.openModelHandoverLink,
                "error-unit": this.errorUnit,
                "unit-id": this.unitId,
                "copy-unit": _ctx.copyUnit
              }, null, 8, ["unit-data", "delete-unit", "publish-unit", "unpublish-unit", "open-visit-link", "open-handover-link", "error-unit", "unit-id", "copy-unit"])
            ]),
            (this.propertyList.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_NoProperty),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('message.property.no_units_yet')), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Pagination, {
              propertyMeta: _ctx.propertyMetaUnit,
              propertyList: _ctx.propertyList,
              page: _ctx.pageUnit,
              handlePage: _ctx.handlePageUnit
            }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isModalOpen)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 2,
            "on-close": _ctx.handleCloseModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.property.warning')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('message.property.sure_delete_building_has_unit')), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", {
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args))),
                    class: "property-section__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.deleteBuilding && _ctx.deleteBuilding(...args))),
                    class: "property-section__modal-button second-button"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('message.delete')) + " ", 1),
                    (this.errorDeleteUnitMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(this.errorDeleteUnitMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (_ctx.isModalUnitOpen)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 3,
            "on-close": _ctx.handleCloseUnitModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('message.property.delete_unit')), 1),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", {
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleCloseUnitModal && _ctx.handleCloseUnitModal(...args))),
                    class: "property-section__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.deleteUnit && _ctx.deleteUnit(...args))),
                    class: "property-section__modal-button second-button"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('message.delete')) + " ", 1),
                    (this.errorDeleteUnitMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(this.errorDeleteUnitMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (_ctx.isModalPublishUnitOpen)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 4,
            "on-close": _ctx.handleClosePublishUnitModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  (this.inActiveHandover)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.$t('message.property.publishing_unit')), 1))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('message.are_you_sure')), 1)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", {
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleClosePublishUnitModal && _ctx.handleClosePublishUnitModal(...args))),
                    class: "property-section__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    class: "property-section__modal-button second-button",
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.publishUnit && _ctx.publishUnit(...args)))
                  }, _toDisplayString(_ctx.$t('message.yes')), 1)
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (_ctx.isModalUnpublishUnitOpen)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 5,
            "on-close": _ctx.handleCloseUnpublishUnitModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('message.are_you_sure')), 1),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", {
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleCloseUnpublishUnitModal && _ctx.handleCloseUnpublishUnitModal(...args))),
                    class: "property-section__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    class: "property-section__modal-button second-button",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.unpublishUnit && _ctx.unpublishUnit(...args)))
                  }, _toDisplayString(_ctx.$t('message.yes')), 1)
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.errorUpublishPopup)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 6,
            "on-close": _ctx.handleCloseUnpublishVisitUnitModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t('message.property.an_active_visit_taking_place')), 1),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("div", {
                    class: "property-section__modal-button-ok second-button",
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleCloseUnpublishVisitUnitModal && _ctx.handleCloseUnpublishVisitUnitModal(...args)))
                  }, _toDisplayString(_ctx.$t('message.ok')), 1)
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.isModalDraftUnitOpen)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 7,
            "on-close": _ctx.handleCloseDraftUnitModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('message.property.unit_has_draft')), 1),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", {
                    class: "property-section__modal-button-ok second-button",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleCloseDraftUnitModal && _ctx.handleCloseDraftUnitModal(...args)))
                  }, _toDisplayString(_ctx.$t('message.ok')), 1)
                ]),
                (this.errorDeleteBuilding)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                      _createVNode(_component_AlertBlock, {
                        message: this.errorDeleteBuilding.property
                      }, null, 8, ["message"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      _createVNode(_component_LinkPopup, {
        "close-modal-link": _ctx.closeModalVisitLink,
        "is-modal-link-open": _ctx.isModalVisitLinkOpen,
        "selected-language-tempalte": _ctx.selectedLanguageTempalte,
        "link-tempalte": _ctx.linkTempalte,
        "update-slots-tempalte": _ctx.updateSlotsTempalte,
        "choosen-lang": _ctx.choosenLang,
        "copy-link": _ctx.copyLink,
        "link-copied": _ctx.linkCopied,
        "link-data": _ctx.linkData,
        "selected-language": _ctx.selectedLanguage,
        "visit-link": this.visitLink,
        "go-to-copy-link-template": _ctx.goToCopyLinkTemplate,
        "update-slots": _ctx.updateSlots,
        "back-to-update-template": _ctx.backToUpdateTemplate,
        "back-to-lang-template": _ctx.backToLangTemplate,
        visit: true
      }, null, 8, ["close-modal-link", "is-modal-link-open", "selected-language-tempalte", "link-tempalte", "update-slots-tempalte", "choosen-lang", "copy-link", "link-copied", "link-data", "selected-language", "visit-link", "go-to-copy-link-template", "update-slots", "back-to-update-template", "back-to-lang-template"]),
      _createVNode(_component_LinkPopup, {
        "close-modal-link": _ctx.closeModelHandoverLink,
        "is-modal-link-open": _ctx.isModalHandoverLinkOpen,
        "selected-language-tempalte": _ctx.selectedLanguageTempalte,
        "link-tempalte": _ctx.linkTempalte,
        "update-slots-tempalte": _ctx.updateSlotsTempalte,
        "choosen-lang": _ctx.choosenLang,
        "copy-link": _ctx.copyLinkHandover,
        "link-copied": _ctx.linkCopied,
        "link-data": _ctx.linkData,
        "selected-language": _ctx.selectedLanguage,
        "visit-link": this.visitLink,
        "go-to-copy-link-template": _ctx.goToCopyLinkTemplate,
        "update-slots": _ctx.updateSlots,
        "back-to-update-template": _ctx.backToUpdateTemplate,
        "back-to-lang-template": _ctx.backToLangTemplate,
        handover: true
      }, null, 8, ["close-modal-link", "is-modal-link-open", "selected-language-tempalte", "link-tempalte", "update-slots-tempalte", "choosen-lang", "copy-link", "link-copied", "link-data", "selected-language", "visit-link", "go-to-copy-link-template", "update-slots", "back-to-update-template", "back-to-lang-template"])
    ])
  ], 64))
}